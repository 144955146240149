import Vue from 'vue';

/**
 * お問い合わせフォーム
 *
 * @export
 * @interface IInquiryForm
 */
export interface IInquiryForm {
  company_name: string;
  name: string;
  pref_name: string;
  email: string;
  confirm: string;
  tel: string;
  body: string;
}

/**
 * お問い合わせ編集フォーム
 *
 * @export
 * @interface IInquiryEditForm
 */
export interface IInquiryEditForm {
  inquiry_id: string;
  inquiry_status: string;
  inquiry_body: string;
  inquiry_company_name: string;
  inquiry_created_at: string;
  inquiry_email: string;
  inquiry_name: string;
  inquiry_pref_name: string;
  inquiry_tel: string;
  inquiry_updated_at: string;
  note_body: string;
}

/**
 * APIレスポンスのぺージング情報
 *
 * @export
 * @interface IPaigingData
 */
export interface IPaigingData {
  total_rows: number;
  total_pages: number;
  page: number;
  rows: number | string; // nolimitもある
  is_first_page: boolean;
  prev_page: number | null;
  is_last_page: boolean;
  next_page: number | null;
}

/**
 * 登録APIレスポンス
 *
 * @export
 * @interface ISavetResponse
 */
export interface ISavetResponse {
  status: string;
  message: any;
  errors?: [];
  error_code: number | null;
  error_message: string | null;
  result: string | any | null;
}

/**
 * アップロードファイル
 *
 * @export
 * @interface IUploadFile
 */
export interface IUploadFile {
  file_id: number;
  file_created_at: string;
  file_memo: string;
  file_mime: string;
  file_original_name: string;
  file_purpose: string;
  file_size: number;
  file_unique_code: string;
  is_image: number;
  user_first_name: string;
  user_id: number;
  user_last_name: string;
}

/**
 * ご案内一覧取得APIレスポンス
 *
 * @export
 * @interface ICmcSearchResponse
 */
export interface ICmcSearchResponse {
  cmc_execute_at: string;
  cmc_title: string;
  cmc_body: string;
  cmc_body_html: string;
  cmc_mail_body: string;
  cmc_mail_body_html: string;
  cmi_created_at: string;
  cmi_displayed_at: string | null;
  cmi_id: number;
  cmi_target_unique_code: string;
  cmi_notified_at: string;
}

/**
 * チャット定型文リクエスト
 *
 * @export
 * @interface IPostUserCFPRequest
 */
export interface IPostUserCFPRequest {
  cfp_id?: string;
  cfp_title: string;
  cfp_body: string;
  cfp_room_types?: string | null;
  cfp_body_html?: string;
  delete?: boolean;
}

/**
 * パスワード変更リクエスト
 *
 * @export
 * @interface IPostUserPassowrdRequest
 */
export interface IPostUserPassowrdRequest {
  current: string | null;
  password: string | null;
  confirm: string | null;
}

/**
 * パートナー配信内容設定リクエスト
 *
 * @export
 * @interface IPartnerInformationRequest
 */
export interface IPartnerInformationRequest {
  pi_status: string | null;
  pi_disable_reason: string | null;
  pi_title: string | null;
  pi_publish_end_date: string | null;
  pi_publish_start_date: string | null;
  pi_image_file: {
    file_original_name: string | null;
    file_purpose: string | null;
    file_size: string | null;
    file_unique_code: string;
    is_image: number | null;
  } | null;
  pi_description: string | null;
  can_edit: boolean | null;
}

/**
 * 退会確認フォーム
 *
 * @export
 * @interface IPostUnsubscribeRequest
 */
export interface IPostUnsubscribeRequest {
  unsubscribe_reason: string;
  unsubscribe_reason_text: string;
}

/**
 * 事業承継のご相談フォーム
 *
 * @export
 * @interface IPostSuccessionRequest
 */
export interface IPostSuccessionRequest {
  category: string;
  body: string;
}

/**
 * 補助金・助成金検索フォーム
 *
 * @export
 * @interface SubsidyForm
 */
export interface SubsidyForm {
  ji_prefectures: string | null;
  exclude_country: number;
  ji_classes_1: string[];
  ji_classes_2: string[];
  keyword: string | null;
  save_conditions: boolean;
  page: number | null;
  rows: number;
}
/**
 * 入札情報詳細フォーム
 *
 * @export
 * @interface IAdminBiddingForm
 */
export interface IAdminBiddingForm {
  bi_unique_code: string | null;
  bi_title: string | null;
  bi_description: string | null;
}

/**
 * ニュース詳細フォーム
 *
 * @export
 * @interface IAdminNewsForm
 */
export interface IAdminNewsForm {
  news_id: string | null;
  news_title: string | null;
  news_content: string | null;
  news_content_html: string | null;
  news_is_general: number | null;
  news_is_hidden: string | null;
  news_is_baportal_enable: number | null;
  news_category: string | null;
  news_category_title: string | null;
  news_published_date: string | null;
  news_created_at: string | null;
  news_creator_user_id: string | null;
  news_is_ba_enable: string | null;
  user_name: string | null;
}

/**
 * メルマガフォーム
 *
 * @export
 * @interface IAdminNewsletterForm
 */
export interface IAdminNewsletterForm {
  newsletter_id: string | null;
  newsletter_title: string | null;
  newsletter_content_body: string | null;
  newsletter_content_body_html: string | null;
  newsletter_publish_date: string | null;
  newsletter_published_at: string | null;
  publish_date: string | null;
  published_time: string | null;
}

/**
 * メルマガ登録フォーム
 *
 * @export
 * @interface IAdminNewsletterCreateForm
 */
export interface IAdminNewsletterCreateForm {
  newsletter_title: string | null;
  newsletter_content_body: string | null;
  publish_date: string | null;
  published_time: string | null;
}

/**
 * ラベルフォーム
 *
 * @export
 * @interface IAdminLabelForm
 */
export interface IAdminLabelForm {
  label_id: string | null;
  label_name: string | null;
  label_memo: string | null;
  label_for_matching: number;
}

/**
 * PR動画配信
 *
 * @export
 * @interface IAdminPromotionForm
 */
export interface IAdminPromotionForm {
  pv_title: string | null;
  pv_order: string | null;
  pv_to_owner: string | null;
  pv_to_employee: string | null;
  pv_is_disabled: string | null;
  pv_html_tag: string | null;
}

/**
 * 一斉ターゲット配信フォーム
 *
 * @export
 * @interface IAdminCmcForm
 */
export interface IAdminCmcForm {
  cmc_id: string | null;
  cmc_title: string;
  cmc_mail_body: string;
  cmc_body: string;
  cmc_scheduled_at: string;
  cmc_description: string;
}

/**
 * チャットワーク設定フォーム
 *
 * @export
 * @interface IAdminChatworkForm
 */
export interface IAdminChatworkForm {
  ca_api_key: string | null;
  ca_room_id: string | null;
}

/**
 * 課金チャージフォーム
 *
 * @export
 * @interface IAdminCompanyChargeForm
 */
export interface IAdminCompanyChargeForm {
  charge_id: string | null;
  charge_company_id: string;
  charge_date: string;
  charge_year: string;
  charge_month: string;
  charge_status: string;
  charge_execute_at: string;
  charge_private_memo: string;
  items: [{
    ci_id: '' | null;
    ci_seq: number;
    ci_type: string;
    ci_title: string;
    ci_description: string;
    ci_memo: string;
    ci_unit_price: number;
    ci_qty: number;
    deleted: boolean;
  }];
}

/**
 * 企業詳細フォーム
 *
 * @export
 * @interface IAdminCompanyForm
 */
export interface IAdminCompanyForm {
  company_id: number | null;
  company_control_number: string;
  company_corporate_number: string;
  company_has_no_bank_branch: number;
  company_bb_id: number;
  company_control_number_2: string;
  company_bank_account_type: string;
  company_bank_account_number: number;
  company_name: string;
  company_name_kana: string;
  company_tel: string;
  company_rep_name: string;
  company_account_closing_month: number;
  company_employee_count: number;
  company_website: string;
  company_cbt_1_code: string;
  company_cbt_2_code: string;
  company_small_business_type: string;
  company_subscribe_reason: string;
  company_type: string;
  company_status: string;
  company_accept_mm: number;
  company_is_other_manager: number;
  company_other_managers: string;
  company_is_partner: number;
  company_check_anti_social: number;
  company_is_matching_fee: number;
  company_is_ca_partner_publish: number;
  company_disable_create_shares_case: number;
  company_private_memo: string;
  company_address: {
    main: {
      post_code_1: string;
      post_code_2: string;
      pref_name: string;
      address_1: string;
      address_2: string;
    };
  },
  company_charge_start_at: string | null;
  company_charge_amount: string | null;
  company_is_charge_target: number | null;
  company_charge_businessuser_amount: number | null;
  company_charge_businessuser_free_cap: number | null;
  company_service_offer_api_url: string;
  owner: {
    user_last_name: string;
    user_first_name: string;
    user_last_name_kana: string;
    user_first_name_kana: string;
    user_email: string;
    user_tel: string;
  }
  partner_request: number;
  company_label_ids: any;
  company_is_demo: number | null;
}

/**
 * 企業登録申請詳細フォーム
 *
 * @export
 * @interface IAdminCompanyregisterForm
 */
export interface IAdminCompanyregisterForm {
  cr_id: number | null;
  show_closed: number | null;
  cr_status: string;
  cr_private_memo: string;
  cr_data: {
    company_control_number: string;
    company_control_number_2: string;
    company_has_no_bank_branch: number;
    large_classification: string;
    middle_classification: string;
    company_small_business_type: string;
    company_name: string;
    company_name_kana: string;
    user_last_name: string;
    user_last_name_kana: string;
    user_first_name: string;
    user_first_name_kana: string;
    user_email: string;
    user_tel: string;
    company_rep_name: string;
    company_tel: string;
    company_rep_birthday: string;
    company_establish_date: string;
    company_account_closing_month: number;
    company_employee_count: number;
    company_website: string;
    company_is_other_manager: number;
    company_other_managers: string;
    company_subscribe_reason: string;
    company_bb_id: number;
    company_address: {
      main: {
        post_code_1: string;
        post_code_2: string;
        pref_name: string;
        address_1: string;
        address_2: string;
      };
      branch: [
        {
          post_code_1: string;
          post_code_2: string;
          pref_name: string;
          address_1: string;
          address_2: string;
        }
      ];
    };
  };
  cr_company_type: string;
}

/**
 * 設定sysdaminフォーム
 *
 * @export
 * @interface IAdminSettingGlobalForm
 */
export interface IAdminSettingGlobalForm {
  settings: {
    can_register_creditcard: 0 | 1 | null;
    is_charge_enable: 0 | 1 | null;
    charge_execute_day: number | null;
    charge_execute_time: number | null;
    charge_config_default_price: number | null;
    charge_config_business_user_unit_price: number | null;
    payment_settings_filled: boolean;
    card_register_start_date: string | null;
    bill_start_date: string | null;
    available_time_branch_admin_start: string | null;
    available_time_branch_admin_end: string | null;
    available_day_branch_admin_sun: 0 | 1 | null;
    available_day_branch_admin_mon: 0 | 1 | null;
    available_day_branch_admin_tue: 0 | 1 | null;
    available_day_branch_admin_wed: 0 | 1 | null;
    available_day_branch_admin_thu: 0 | 1 | null;
    available_day_branch_admin_fri: 0 | 1 | null;
    available_day_branch_admin_sat: 0 | 1 | null;
    available_time_branch_user_start: string | null;
    available_time_branch_user_end: string | null;
    available_day_branch_user_sun: 0 | 1 | null;
    available_day_branch_user_mon: 0 | 1 | null;
    available_day_branch_user_tue: 0 | 1 | null;
    available_day_branch_user_wed: 0 | 1 | null;
    available_day_branch_user_thu: 0 | 1 | null;
    available_day_branch_user_fri: 0 | 1 | null;
    available_day_branch_user_sat: 0 | 1 | null;
    ipaddress_admin_enable: 0 | 1;
    ipaddress_admin_ip_addresses: string | null;
    chat_delete_attachment_file_after_period: 0 | 1 | null;
    business_user_enable: 0 | 1 | null;
    mail_notification_enable: 0 | 1 | null;
  };
}
/**
 * @interface IAdminSettingSysadminForm
 */
export interface IAdminSettingSysadminForm {
  settings: {
    baportal: {
      enable: number | null;
    };
    forum: {
      enable: number | null;
    };
    auba: {
      company: {
        enable: number | null;
      }
    };
    customize: {
      chat_link_visible: string | null;
      ncba_bm_visible: string | null;
      bank_branches_visible: number | null;
      subscribe_reason_visible: string | null;
      main_account_visible: number | null;
      register_reason_visible: number | null;
      ba_chat_not_approve_own: number | null;
    };
    contents: {
      rule_contents: {
        text: string | null;
      };
    };
    can_register_creditcard: boolean | null;
    is_charge_enable: number | null;
    charge_execute_day: number | null;
    charge_execute_time: number | null;
    charge_config: {
      default_price: number | null;
      business_user: {
        unit_price: number | null;
        free_cap: number | null;
      };
    };
    charge: {
      card_register_start_date: string | null;
      bill_start_date: string | null;
      bill_start_month: string | null;
    };
  };
}

/**
 * オープンイノベーションエントリーフォーム
 *
 * @export
 * @interface IInnovationEntrynRequest
 */
export interface IInnovationEntrynRequest {
  i_unique_code: string;
  message: string;
}

/**
 * イノベーション履歴フォーム
 *
 * @export
 * @interface IAdminCompanyInnovationHistoryForm
 */
export interface IAdminCompanyInnovationHistoryForm {
  cih_id?: number;
  cih_status: string;
}

/**
 * ピックアップフォーム
 *
 * @export
 * @interface IAdminPickUpForm
 */
export interface IAdminPickUpForm {
  pc_target_unique_code: string | null;
  pc_type: string | null;
  action: string | null;
}

/**
 * セミナー申し込みフォーム
 *
 * @export
 * @interface ISeminarEntryForm
 */
export interface ISeminarEntryForm {
  email: string;
  entry_num: number;
}

/**
 * 企業ユーザ詳細フォーム
 *
 * @export
 * @interface IAdminUserEditForm
 */
export interface IAdminUserEditForm {
  user_id: number;
  user_last_name: string;
  user_first_name: string | null;
  user_email: string | null;
  user_last_name_kana: string | null;
  user_first_name_kana: string | null;
  user_tel: string | null;
  user_broker_code: string | null;
  user_broker_sub: string | null;
  user_broker_company_code: string | null;
  user_broker_company_code_2: string | null;
  user_type: string | null;
}

/**
 * バナー詳細フォーム
 *
 * @export
 * @interface IAdminBannerEditForm
 */
export interface IAdminBannerEditForm {
  banner_id?: number | null;
  banner_title: string;
  banner_url: string;
  banner_order: number;
  banner_to_employee: number;
  banner_to_owner: number;
  file?: IUploadFile | null;
  banner_is_ba_enable?: number;
  banner_is_baportal_enable?: number;
  file_unique_code?: string;
}
/**
 * パートナー情報発信詳細登録フォーム
 *
 * @export
 * @interface IAdminPartnerInformationForm
 */
export interface IAdminPartnerInformationForm {
  pi_id: string;
  pi_status: string;
  pi_disable_reason: string | null;
  pi_approval_date: string | null;
  pi_partner_company_id: string;
}
/**
 * セミナー詳細フォーム
 *
 * @export
 * @interface IAdminSeminarEditForm
 */
export interface IAdminSeminarEditForm {
  seminar_id?: number | null;
  seminar_title: string;
  seminar_description: string;
  seminar_status?: string;
  seminar_status_name?: string;
  seminar_date: string;
  seminar_time: string;
  seminar_capacity: number;
  seminar_capacity_is_unlimited: number;
  seminar_location_address: string;
  seminar_price: string;
  seminar_promoter: string;
  seminar_is_open: number;
  seminar_detail_can_apply: string;
  seminar_image_file: IUploadFile | null;
  seminar_image_file_original_name?: string | null;
  seminar_image_file_unique_code?: string | null;
  seminar_detail_file: IUploadFile | null;
  seminar_detail_file_original_name?: string | null;
  seminar_detail_file_unique_code?: string | null;
}

/**
 * セミナー参加者メモフォーム
 *
 * @export
 * @interface IAdminSeminarUserMemoForm
 */
export interface IAdminSeminarUserMemoForm {
  su_unique_code: string | null;
  su_note: string;
}

/*
 * ユーザ書類詳細フォーム
 *
 * @export
 * @interface IAdminDocumentEditForm
 */
export interface IAdminDocumentEditForm {
  document_id?: number | null;
  document_category: number;
  document_order: number;
  document_is_disabled?: string;
  document_title: string;
  document_file?: IUploadFile | null;
  file_unique_code?: string | null;
}

/**
 * 社内書類ファイル
 *
 * @export
 * @interface IHandoutFileForm
 */
export interface IHandoutFileForm extends IUploadFile {
  delete: number;
}

/**
 * 社内書類詳細フォーム
 *
 * @export
 * @interface IHandoutEditForm
 */
export interface IHandoutEditForm {
  handout_id?: number;
  handout_title: string;
  handout_order: number;
  handout_memo: string;
  handout_files: IHandoutFileForm[];
}
/*
 * ユーザ管理詳細フォーム
 *
 * @export
 * @interface IAdminUserAdminEditForm
 */
export interface IAdminUserAdminEditForm {
  user_id?: number | null;
  user_bb_id?: number;
  user_type: string;
  user_is_disabled?: string;
  user_login_id?: string;
  user_last_name?: string;
  user_first_name?: string;
  user_email?: string;
  user_branches?: Array<string>;
}

/*
 * 2段階認証フォーム
 *
 * @export
 * @interface IAdminUserAdminTwoFactorForm
 */
export interface IAdminUserAdminTwoFactorForm {
  type?: string | null;
  type_value?: string;
  two_factor_auth_password: string | null;
  two_factor_auth_password_confirm?: string | null;
}

/**
 *  v-formの型
 */
export type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => boolean;
  reset: () => void;
};

/**
 *  v-selectの型
 */
export type VSelect = Vue & {
  validate: () => boolean;
  resetValidation: () => boolean;
  reset: () => void;
};

/**
 * 入札情報カテゴリ一覧取得APIレスポンス
 *
 * @export
 * @interface IBiddingCategory
 */
export interface IBiddingCategory {
  [key: string]: string;
}

/**
 * ビジネスマッチング基本情報フォーム
 *
 * @export
 * @interface ICompanyMyBusinessForm
 */
export interface ICompanyMyBusinessForm {
  資本金: string;
  年商: string;
  サービスの特徴: string;
  自社の強み: string;
  自社の経営課題: string;
  備考: string;
}

/**
 * 自社ニーズ設定フォーム
 *
 * @export
 * @interface INeedsForm
 */
export interface INeedsForm {
  needs_unique_code: string;
  ca_is_public: number | null;
  ca_type_unique_code: string;
  ca_title: string;
  ca_body: string | null;
  ca_partner_business_type: string | null;
  ca_partner_requirement: string;
  ca_picture: any;
  ca_document: any;
}

/**
 * ニーズ通知設定フォーム
 *
 * @export
 * @interface IMatchingSearchForm
 */
export interface IMatchingSearchForm {
  keyword: string | null;
  rows: string | null;
  cbt_code: any | null;
  pref_name: any | null;
  search_partner: string | null;
  ca_type_unique_code: string | null;
}

/**
 * 商談連絡先設定フォーム
 *
 * @export
 * @interface IMatchingContactForm
 */
export interface IMatchingContactForm {
  cmhc_department_name: string;
  cmhc_rep_position: string;
  cmhc_rep_name: string;
  cmhc_tel: string | null;
  cmhc_mail: string | null;
}

/**
 * 商談依頼回答フォーム
 *
 * @export
 * @interface IMatchingReply
 */
export interface IMatchingReply {
  cmh_unique_code: string;
  cmh_destination_contact_unique_code: string;
  cmh_is_reply_approve: number | null;
}

/**
 * 企業登録フォーム
 *
 * @export
 * @interface IRegisterForm
 */
export interface IRegisterForm {
  user_last_name: string;
  user_first_name: string;
  user_last_name_kana: string;
  user_first_name_kana: string;
  user_email: string;
  confirm_user_email: string;
  user_tel: string;
  company_corporate_number: string;
  company_name: string;
  company_name_kana: string;
  company_tel: string;
  company_rep_name: string;
  company_rep_birthday: string;
  company_establish_date: string;
  company_account_closing_month: number | null;
  company_employee_count: number;
  company_website: string;
  company_address: {
    main: {
      post_code_1: string;
      post_code_2: string;
      pref_name: string;
      address_1: string;
      address_2: string;
    };
    branch: [
      {
        post_code_1: string;
        post_code_2: string;
        pref_name: string;
        address_1: string;
        address_2: string;
      }
    ];
  };
  large_classification: string;
  middle_classification: string;
  company_small_business_type: string;
  company_has_no_bank_branch: number;
  company_bb_id: number;
  company_bank_account_type: string;
  company_bank_account_number: number;
  company_identification_file: any;
  company_is_other_manager: number;
  company_other_managers: string;
  company_interesting_features: string[];
  cc_token: string;
  cc_sp_masked_card_number: string;
  agree1: boolean;
  agree1_unixtime: number | null;
  company_check_anti_social: number;
  agree2: boolean;
  bundle_payment_plan_id: number;
  agree_bundle_payment: boolean;
}

/**
 * ポータル企業登録フォーム
 *
 * @export
 * @interface IRegisterPortalForm
 */
export interface IRegisterPortalForm {
  user_last_name: string;
  user_first_name: string;
  user_last_name_kana: string;
  user_first_name_kana: string;
  user_email: string;
  user_tel: string;
  company_name: string;
  company_has_no_bank_branch: number;
  large_classification: string;
  middle_classification: string;
  company_small_business_type: string;
}

/**
 * クーポン詳細フォーム
 *
 * @export
 * @interface ICompanyServiceOfferForm
 */
export interface ICompanyServiceOfferForm {
  bb_name: string;
  company_name: string;
  cso_id: number;
  cso_unique_code: string;
  cso_catch_copy: string;
  cso_category_name: string;
  cso_category_seq: number;
  cso_category_unique_code: string;
  cso_company_id: number;
  cso_company_is_partner: boolean;
  cso_created_at: string;
  cso_image_file_id: number;
  cso_image_file_unique_code: string;
  cso_is_recommended: number;
  cso_limit_date: string;
  cso_message: string;
  cso_message_html: string;
  cso_note: string;
  cso_note_html: string;
  cso_offer_url: string;
  cso_pdf_file_data: any;
  cso_pdf_file_id: number;
  cso_pdf_file_unique_code: number;
  cso_publisher_name: string;
  cso_publisher_unique_code: string;
  cso_status: string;
  cso_status_name: string;
  cso_publish_status: string;
  cso_publish_status_name: string;
  cso_type: number;
  cso_updated_at: string;
  csos_address: string;
  csos_tel: string;
  csos_title: string;
  is_draft: number;
  pdf: any;
  images: any;
  sites: any;
}

/**
 * 従業員登録フォーム
 *
 * @export
 * @interface IRegisterEmployeeForm
 */
export interface IRegisterEmployeeForm {
  code: string | null;
  user_last_name: string;
  request_password: string;
  request_password_repeat: string;
}

/**
 * 企業編集フォーム
 *
 * @export
 * @interface ICompanyEditForm
 */
export interface ICompanyEditForm {
  company_corporate_number: string;
  company_name: string;
  company_name_kana: string;
  company_tel: string;
  company_rep_name: string;
  company_rep_birthday: string;
  company_establish_date: string;
  company_account_closing_month: number;
  company_employee_count: number;
  company_website: string;
  company_logo_file: {} | null;
  remove_company_logo: {} | null;
  company_background_file: {} | null;
  remove_company_background: {} | null;
  company_address: {
    main: {
      post_code: string;
      post_code_1: string;
      post_code_2: string;
      pref_name: string;
      address: string;
      address_1: string;
      address_2: string;
    };
    branch: [
      {
        post_code_1: string;
        post_code_2: string;
        pref_name: string;
        address_1: string;
        address_2: string;
      }
    ];
  };
  large_classification: string;
  middle_classification: string;
  company_cbt_1_code: string | null;
  company_cbt_2_code: string | null;
  company_small_business_type: string;
  company_has_no_bank_branch: number;
  company_bb_id: number;
  company_bank_account_type: string;
  company_bank_account_number: number;
}

/**
 * FAQ情報詳細フォーム
 *
 * @export
 * @interface IFAQForm
 */
export interface IFAQForm {
  a_id: number | null;
  a_title: string | null;
  a_content: string | null;
  a_content_html: string | null;
  a_seq: number | null;
  a_is_hidden: number | null;
  a_created_at: string | null;
  a_updated_at: string | null;
}

/**
 * FAQ情報新規作成フォーム
 *
 * @export
 * @interface IFAQCreateForm
 */
export interface IFAQCreateForm {
  a_title: string | null;
  a_content: string | null;
  a_seq: number | null;
  a_is_hidden: number | null;
}

/**
 * 金融機関連絡チャット一時保存フォーム
 *
 * @export
 * @interface bachatRemarks
 */
export interface IBachatRemarks {
  bachatroomUniqueCode: string | null;
  bachatRemarks: {
    attachmentFiles: string[] | null;
    chatremarkBody: string | null;
    chatroom: any | null;
  };
}

/**
 * 金融機関連絡チャット送信フォーム
 *
 * @export
 * @interface IChatRemark
 */
export interface IChatRemark {
  attachment_files: string[] | null;
  chatremark_body: string | null;
  chatroom: any | null;
}

/**
 * ルーム作成フォーム
 *
 * @export
 * @interface IChatRoomCreate
 */
export interface IChatRoomCreate {
  chatroom_description: string | null;
  chatroom_title: string | null;
  members: any | null;
}

/**
 * ユーザーグループ作成フォーム
 *
 * @export
 * @interface IChatRoomCreate
 */
export interface IUserGroupCreate {
  members: any[];
  ug_description: string;
  ug_name: string;
}

/**
 * メンバー招待フォーム
 *
 * @export
 * @interface IChatRoomInvitationsCreate
 */
export interface IChatRoomInvitationsCreate {
  additional_members: {} | null;
  cri_code: string | null;
}

/**
 * ホームページ管理フォーム
 *
 * @export
 * @interface ICwWebsiteForm
 */
export interface ICwWebsiteForm {
  unique_code: string | null;
  published: number | null;
  url: string | null;
  draft_template_code: string | null;
  company_unique_code: string | null;
  company: {
    company_name: string | null;
    company_name_kana: string | null;
    company_logo_file: {} | null;
    company_logo_file_unique_code: string | null;
    company_logo_file_url: string | null;
    remove_company_logo: boolean | null;
    company_background_file: {} | null;
    company_background_file_unique_code: string | null;
    company_background_file_url: string | null;
    remove_company_background: boolean | null;
    company_employee_count: string | null;
    company_tel: string | null;
    company_rep_name: string | null;
    company_establish_date: string | null;
    company_website: string | null;
    company_owner_user_unique_code: string | null;
    cbt_name: string | null;
  };
  draft_data: {
    meta_title: string | null;
    meta_description: string | null;
    meta_keywords: string | null;
    contact_tel: string | null;
    contact_mail: string | null;
    business_hour: string | null;
    googlemap: string | null;
    sns: {
      facebook: {
        url: string | null;
      } | null;
      twitter: {
        url: string | null;
      } | null;
      instagram: {
        url: string | null;
      } | null;
    } | null;
    main_color: string | null;
    menu_font_color: string | null;
    button_color: string | null;
    catch_font_color: string | null;
    vision_title: string | null;
    vision_contents: string | null;
    business_sub_title: string | null;
    business_item_title: string | null;
    business: [
      {
        title: string | null;
        contents: string | null;
        outline: string | null;
        image_file_unique_code: string | null;
        image_file_url: string | null;
      }
    ];
    company_building: {
      image_file_unique_code: string | null;
      image_file_url: string | null;
    } | null;
    company_service: string | null;
    company_concept_title: string | null;
    company_concept_contents: string | null;
    company_rep: {
      position: string | null;
      message: string | null;
      image_file_unique_code: string | null;
      image_file_url: string | null;
    } | null;
    history:
    | [
      {
        date: string | null;
        contents: string | null;
      }
    ]
    | null;
    items_page_title: string | null;
    items_page_sub_title: string | null;
    items:
    | [
      {
        date: string | null;
        name: string | null;
        outline: string | null;
        contents: string | null;
        image:
        | [
          {
            image_file_unique_code: string | null;
            image_file_url: string | null;
          }
        ]
        | null;
      }
    ]
    | null;
    informations:
    | [
      {
        date: string | null;
        title: string | null;
        contents: string | null;
        image_file_unique_code: string | null;
        image_file_url: string | null;
      }
    ]
    | null;
  };
  draft_is_phone_number_public: boolean | null;
}

/**
 * オブジェクトのKeyValueの定義
 *
 * @export
 * @interface StringKeyObject
 */
export interface StringKeyObject {
  [key: string]: any;
}

/**
 * HTMLイベントオブジェクトのターゲット内のファイルの型定義
 *
 * @export
 * @interface HTMLInputEvent
 */
export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

/**
 * 管理画面チャット
 *
 * @export
 * @interface IChatRoomCreate
 */
export interface IAdminBaChatRemark {
  user_unique_code?: string | null;
  bachatroom_cmh_unique_code?: string | null;
  bank_code?: string | null;
  counter_bank_code?: string | null;
  bachatroom_unique_code: string | null;
  chatremark_body: string | null;
  attachment_files: [];
  chatroom: { bachatroom_unique_code: string };
}

/**
 * 商談依頼ステータス変更フォーム
 *
 * @export
 * @interface IAdminCmhStatusEditForm
 */
export interface IAdminCmhStatusEditForm {
  cmh_unique_code: string | null;
  cmh_status: string | null;
  cmh_message: string | null;
  note_body: string | null;
}

/**
 * メモ編集フォーム
 *
 * @export
 * @interface IAdminNoteEditForm
 */
export interface IAdminNoteEditForm {
  note_id: number | null;
  note_body: string;
  note_reference_common_id: number | null;
  note_reference_unique_code: string;
  note_type: string;
  note_target: string;
}

/**
 * 商談依頼メモ編集フォーム
 *
 * @export
 * @interface IAdminOfferNoteEditForm
 */
export interface IAdminOfferNoteEditForm {
  offer_memo_unique_code: string;
  note_body: string;
  note_reference_common_id: number | null;
  note_reference_unique_code: string;
  note_type: string;
  note_target: string;
}

/**
 * 商談依頼連絡先編集フォーム
 *
 * @export
 * @interface IOfferContactEditForm
 */
export interface IOfferContactEditForm {
  cmhc_unique_code: string;
  cmhc_rep_name: string;
  cmhc_department_name: string;
  cmhc_rep_position: string;
  cmhc_tel: string,
  cmhc_mail: string,
}

/**
 * イノベーション詳細フォーム
 *
 * @export
 * @interface IAdminInnovationEditForm
 */
export interface IAdminInnovationEditForm {
  can_edit: boolean;
  company_name: string | null;
  company_unique_code: string | null;
  i_bank_code: string | null;
  i_company_id: number | null;
  i_condition: string | null;
  i_condition_html: string | null;
  i_cover_image_file: {
    file_original_name: string | null;
    file_purpose: string | null;
    file_size: string | null;
    file_unique_code: string | null;
    is_image: number | null;
  } | null;
  i_cover_image_file_path: string | null;
  i_cover_image_file_unique_code: string | null;
  i_created_at: string | null;
  i_description: string | null;
  i_description_html: string | null;
  i_entry_limit_date: string | null;
  i_id: number | null;
  i_profile: string | null;
  i_profile_html: string | null;
  i_resources: [] | null;
  i_status: string | null;
  i_status_name: string | null;
  i_title: string | null;
  i_unique_code: string | null;
  i_updated_at: string | null;
  i_auba_id: string | null;
}

/**
 * ニーズ作成
 *
 * @export
 * @interface ICompanyAdvertisementCreate
 */
export interface ICompanyAdvertisementCreate {
  company_unique_code: string;
  needs_unique_code: string | null;
  ca_type_unique_code: string;
  ca_is_public: number;
  ca_title: string;
  ca_partner_business_type: string;
  ca_body: string;
  ca_partner_requirement: string;
  ca_picture: {
    file_original_name: string | null;
    file_purpose: string | null;
    file_size: string | null;
    file_unique_code: string | null;
    is_image: number | null;
  } | null;
  ca_document: {
    file_original_name: string | null;
    file_purpose: string | null;
    file_size: string | null;
    file_unique_code: string | null;
    is_image: number | null;
  } | null;
  needs_publish_start_date: string;
  needs_publish_end_date: string;
  target_prefecture_codes: string[] | null;
}

/**
 * オープンイノベーションフォーム
 *
 * @export
 * @interface IInnovationRequest
 */
export interface IInnovationRequest {
  i_title: string | null;
  i_entry_limit_date: string | null;
  i_profile: string | null;
  i_description: string | null;
  i_condition: string | null;
  i_cover_image_file: {
    file_original_name: string | null;
    file_purpose: string | null;
    file_size: string | null;
    file_unique_code: string | null;
    is_image: number | null;
  } | null;
  i_resources: [
    {
      title: string | null;
      picture: {
        file_original_name: string | null;
        file_purpose: string | null;
        file_size: string | null;
        file_unique_code: string | null;
        is_image: number | null;
      } | null;
      description: string | null;
    },
  ] | null;
}

/**
 * チャット定型文作成フォーム
 *
 * @export
 * @interface IChatFixedphrase
 */
export interface IChatFixedphrase {
  cfp_id: number | null,
  cfp_title: string | null;
  cfp_body: string | null;
  cfp_order: number | null;
  cfp_room_types: string[];
}

/**
 * 一斉ターゲット配信フォーム
 *
 * @export
 * @interface IChatMassContent
 */
export interface IChatMassContent {
  cmc_title: string;
  cmc_mail_body: string;
  chatremark_body: string;
  cmc_scheduled_at: string;
  description: string;
}

/**
 * メール再送信フォーム
 *
 * @export
 * @interface IAdminEventDuplicateForm
 */
export interface IAdminEventDuplicateForm {
  change_ed_email_to: string;
  ed_email_to: string;
}

/**
 * 士業相談依頼キャンセルフォーム
 *
 * @export
 * @interface ICancelCaseForm
 */
export interface ICancelCaseForm {
  reason: string;
  other_reason: string | null;
}

/**
 * チャットルームメンバー編集フォーム
 *
 * @export
 * @interface IChatRoomMembers
 */
export interface IChatRoomMembers {
  chatroom_unique_code: string | null;
  members: [];
}

/**
 * 機能要望投稿フォーム
 *
 * @export
 * @interface IContactForm
 */
export interface IContactForm {
  body: string;
}

/**
 * パートナー申請依頼フォーム
 *
 * @export
 * @interface IPartnerRequestForm
 */
export interface IPartnerRequestForm {
  bank_name: string;
  company_name: string;
  home_page_url: string;
  listed: '上場企業' | '上場G企業' | '非営利組織（自治体・商工会等）' | 'その他' | null;
  listed_detail: string;
  capital: string;
  sales: string;
  company_info: string
  reason: string;
  attachment_files: []
}

/**
 * ビジネスマッチング 同意確認フォーム
 *
 * @export
 * @interface INcbaBmAgreementForm
 */
export interface INcbaBmAgreementForm {
  ncm_company_name: string | null;
  ncm_company_name_kana: string | null;
  ncm_detail: string | null;
  ncm_company_contact_post: string | null;
  ncm_company_contact_name: string | null;
  ncm_company_contact_name_kana: string | null;
  ncm_company_tel: string | null;
  ncm_company_email_address: string | null;
  ncm_company_bb_id: string | null;
  ncm_mybank_attendant_name: string | null;
  agree_rule: string | null;
  read_rule: boolean | null;
}

/**
 * 有償BM提案作成フォーム
 *
 * @export
 * @interface IBmCreateForm
 */
export interface IBmCreateForm {
  title: string;
  ca_list: any;
  extra: any
  // extra?: {
  //   name: string;
  //   type: string;
  //   status: string;
  //   purpose: string;
  //   address: string;
  //   land_area: string;
  //   building_area: string;
  //   amount: string;
  //   remarks: string;
  // } | null;
}

/**
 * ポータルからBA会員登録同意フォーム
 *
 * @export
 * @interface IBmCreateForm
 */
export interface IBaPortalForm {
  agree1: boolean;
  agree1_unixtime: number;
  cc_token: string;
  cc_sp_masked_card_number: string;
  _token: string;
  agree2: boolean;
}

/**
 * ID_TOKEN
 *
 * @export
 * @interface IIdToken
 */
export interface IIdToken {
  aud: string,
  ba_company_disable_create_shares_case: string,
  ba_company_enable_ba: string,
  ba_company_enable_baportal: string,
  ba_company_name: string,
  ba_company_type: string,
  ba_company_unique_code: string,
  ba_user_email: string,
  ba_user_name: string,
  ba_user_type: string,
  ba_user_unique_code: string,
  broker_code: string,
  broker_user_sub: string
  exp: number,
  iat: number
  iss: string,
  jwk: string,
  oit_code: string,
  realm: string,
  sub: string,
  user_switch_from: string
}

/**
 * CAMPFIREプロジェクト新規作成/編集フォーム
 *
 * @export
 * @interface IAdminCrowdfundingCampfireForm
 */
export interface IAdminCrowdfundingCampfireForm {
  crowdfunding_campfire_id?: number | null;
  crowdfunding_campfire_bank_code: string;
  crowdfunding_campfire_project_name: string;
  crowdfunding_campfire_origin_company_id: number;
  crowdfunding_campfire_is_public: boolean;
  crowdfunding_campfire_project_starting_date: string;
  crowdfunding_campfire_project_closing_date?: string | null;
  crowdfunding_campfire_project_id: string;
}

/**
 * 事業承継株価資産入力フォーム
 *
 * @export
 * @interface IBusinessSuccessionStokForm
 */
export interface IBusinessSuccessionStokForm {
  calculation_method: string;
  industry_stock_type_large?: string | null;
  industry_stock_type_middle?: string | null;
  industry_stock_type_small?: string | null;
  // inheritance
  dividends: number;
  income_before_income_tax: number;
  before_the_previous_income_before_income_tax: number;
  capital: number;
  capital_surplus: number;
  retained_earnings: number;
  number_of_employees: number;
  total_assets: number;
  // m and a;
  operating_income: number;
  reducible_expense: number;
  // both
  sales: number;
  number_of_issued_shares: number;
  net_asset: number;
  unrealized_gain: number
}

/**
 * 事業承継相続税入力フォーム
 *
 * @export
 * @interface IBusinessSuccessionTaxForm
 */
export interface IBusinessSuccessionTaxForm {
  number_of_spouse: number;
  number_of_children: number | null;
  number_of_parents: number | null;
  number_of_siblings: number | null;
  spouse_real_inheritance_rate: number;
  child_1_real_inheritance_rate: number;
  child_2_real_inheritance_rate: number;
  child_3_real_inheritance_rate: number;
  child_4_real_inheritance_rate: number;
  child_5_real_inheritance_rate: number;
  parent_1_real_inheritance_rate: number;
  parent_2_real_inheritance_rate: number;
  sibling_1_real_inheritance_rate: number;
  sibling_2_real_inheritance_rate: number;
  sibling_3_real_inheritance_rate: number;
  sibling_4_real_inheritance_rate: number;
  sibling_5_real_inheritance_rate: number;
  cash: number;
  securities: number;
  real_estate: number;
  relatives_inheritance_per_share: number;
  number_of_owned_shares: number;
  loan: number;
  other: number;
  insurance: number;
  borrowings: number;
}
/**
 * 補助金申請支援無料相談申し込みフォーム
 *
 * @export
 * @interface ISubsidySupportContactForm
 */
export interface ISubsidySupportContactForm {
  bank_code: string;
  name: string;
  department: string;
  mail: string;
  tel: string;
  contents: string;
}

/**
 * ダッシュボード通知カードリスト
 *
 * @export
 * @interface IDashboardNotificationCardListState
 */
export interface IDashboardNotificationCardListState {
  chatUnreadTotal: number,
  innovationHistoryCreatedTotal: number,
  innovationCreatedTotal: number
}
